import state from './moduleScheduleTheoreticalExamState.js'
import mutations from './moduleScheduleTheoreticalExamMutations.js'
import actions from './moduleScheduleTheoreticalExamActions.js'
import getters from './moduleScheduleTheoreticalExamGetters.js'

export default {
  isRegistered: false,
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}

